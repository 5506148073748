import { cx, css } from '@emotion/css';
import React, { FC } from 'react';

export const LoginHeader: FC = () => {
  return <div className={cx(loginHeader)}></div>;
};

const loginHeader = css`
  background: url(public/img/helmholtz/login-header-v3.png) 0 0 no-repeat;
  background-size: cover;
  height: 200px;
  padding-top: 10px;
  width: 100%;

  div {
    display: block;
    padding: 24px 0 24px 50px;
  }
`;
