import { cx, css } from '@emotion/css';
import React, { FC, CSSProperties } from 'react';

type LoginInfoBoxProps = {
  children?: JSX.Element | JSX.Element[];
  style?: CSSProperties;
}

export const LoginInfoBox: FC<LoginInfoBoxProps> = ({ children, style }) => {
  return (
    <div className={cx(loginInfoBox)} style={style}>
      {children}
    </div>
  );
};

const loginInfoBox = css`
  background: #181b1fb3;
  border-radius: 8px;
  font-size: 1.1em;
  margin: 0 20px;
  min-height: 464px;
  padding: 30px;
  opacity: 1;
  overflow: hidden;
  width: 25%;

  h2 {
    font-family: 'Helvetica';
    margin-bottom: 20px;
  }

  a {
    text-decoration: underline;
  }

  figcaption {
    position: relative;
    top: 25rem;
  }
`;
