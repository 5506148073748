import { cx, css } from '@emotion/css';
import React, { FC } from 'react';

export const ProjectDetailOverlay: FC = ({}) => {
  const closeOverlay = (event: React.MouseEvent) => {
    event.preventDefault();
    const overlay = document.getElementById('project-overlay');
    if (overlay) {
      overlay.style.display = 'none';
    }
  };

  return (
    <div className={cx(overlay)} id="project-overlay">
      <iframe
        title="project-details"
        src="" scrolling="yes"
        frameBorder="0"
        width="100%"
        height="100%"
      />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        title="closeOverlay"
        href="#"
        className={cx(closeBtn)}
        onClick={closeOverlay}
      >Close</a>
    </div>
  );
};

const overlay = css`
  background: #000;
  display: none;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 100px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  iframe {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }
`;

const closeBtn = css`
  background: url('public/img/icons/custom/cancel.png') 0 0/30px 30px no-repeat;
  display: block;
  filter: invert(100%);
  height: 30px;
  position: absolute;
  right: 25px;
  width: 30px;
  top: 25px;
`;
