import { cx, css } from '@emotion/css';
import React, { FC } from 'react';

export const LoginNavigation: FC = ({}) => {
  return (
    <div className={cx(navTopWrapper)}>
      <ul className={cx(nav)}>
        <li>
          <a className={cx(navLink)} href="/help/" target="_blank">
            Help
          </a>
        </li>
        <li>
          <a
            className={cx(navLink)}
            href="mailto:datahub-support@gfz-potsdam.de?subject=TSM Help"
            target="_blank"
            rel="noreferrer"
          >
            Integrate my data
          </a>
        </li>
      </ul>
    </div>
  );
};

const nav = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  list-style: none;
  padding-left: 0;
`;

const navTopWrapper = css`
  background-color: #fff;
  border-bottom: 1px solid rgb(204, 204, 220);
  padding: 10px 10px 10px 0;
  width: 100%;
`;

const navLink = css`
  color: #0a2d6e;
  display: block;
  font-size: 1.2em;
  padding: 0.5rem 1rem;
`;
