export class HelmholtzBranding {
  static MenuLogoStyles = { borderRadius: 0 };
  static NavBarItemStyles = { color: '#9fa7b3' };
  static SideMenuStyles = { backgroundColor: '#0a2d6e' };

  static LoginInfoBoxProject1 = {
    background: 'url(public/img/helmholtz/moses.jpg) 0 0 no-repeat',
    minHeight: '30rem',
  };
  static LoginInfoBoxProject2 = {
    background: 'url(public/img/helmholtz/tereno.jpg) 0 0 no-repeat',
    minHeight: '30rem',
  };
  static LoginInfoBoxProject3 = {
    background: 'url(public/img/helmholtz/helgog.jpg) 0 0 no-repeat',
    minHeight: '30rem',
  };
  static LoginInfoBoxNoBg = { backgroundColor: '#0a2d6e', minHeight: '150px' };
  static LoginInfoBoxNews = { maxHeight: '464px', overflow: 'auto' };
  static DatahubLogo = {
    background: 'url(public/img/helmholtz/datahub.png) 0 0 no-repeat',
    width: '300px',
    height: '47px',
    margin: '20px 46px',
  };
  static AppTitle = 'Datahub Observatory View';
}
