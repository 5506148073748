import { cx, css } from '@emotion/css';
import React from 'react';

import fetchNews from '../../services/NewsService';

type Post = {
  create_at: string;
  message: string;
}
export class LoginNewsBox extends React.Component {
  state: { posts: never[]; };

  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = { posts: [] };
  }

  render() {
    return (
      <ul className={cx(newsEntries)}>
        {this.state.posts.map((post: Post, index: number) => {
          return (
            <li key={index}>
              <div className="date">{post.create_at}</div>
              <div>{post.message}</div>
            </li>
          );
        })}
      </ul>
    );
  }

  componentDidMount() {
    this.fetchNewsData();
  }

  fetchNewsData() {
    fetchNews().then((response) => {
      this.setState({ posts: response['posts'] });
    });
  }
}

const newsEntries = css`
  height: 100%;
  overflow: auto;
  scrollbar-color: rgb(204, 204, 220) transparent;

  li {
    padding-bottom: 25px;
  }

  .date {
    font-size: 0.8em;
  }
`;
