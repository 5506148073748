export default function fetchNews() {
  const newsFeedRoute = '/mattermost/newsfeed';
  let data = { posts: [] };

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');

  const url = new URL(window.location.protocol + window.location.host);
  url.pathname = newsFeedRoute;

  return fetch(url.toString(), {
    method: 'GET',
    headers: headers,
  })
    .then((response) => {
      if (response.ok) {
        // @ts-ignore
        data = response.json();
      }
      return data;
    })
    .catch((err) => data);
}
